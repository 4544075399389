import React from 'react';

import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '@rotaryintl/component-button';

export type HarrisButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  disabled?: boolean;
  icon?: string | number;
  url?: string;
  target?: string;
  clickHandler?: () => void;
  children: React.ReactNode;
  className?: unknown;
};

const HarrisButton: React.FC<HarrisButtonProps> = ({
  children,
  ...restProps
}) => {
  return <Button {...restProps}>{children}</Button>;
};

export default HarrisButton;
